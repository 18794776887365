import React, { useEffect } from "react";
import Helmet from "react-helmet";
// import { Link } from "gatsby";
// import Img from "gatsby-image";
import Layout from "../layout";
import moment from "moment";
// import { normalizePath } from "../../utils/get-url-path";
// import { Disqus, CommentCount } from "gatsby-plugin-disqus";
import { Disqus } from "gatsby-plugin-disqus";
import useSiteMetadata from "../../hooks/use-site-metadata";
import SEO from "../seo";

function DefaultPost({ data }) {
  // const { nextPage, previousPage, page } = data;
  const { siteURL } = useSiteMetadata();
  const { page } = data;
  const {
    id,
    slug,
    title,
    excerpt,
    content,
    date,
    author,
    featuredImage,
  } = page;

  const excerptString = excerpt.substring(3);
  const trimmedString = excerptString.slice(0, -5);

  let disqusConfig = {
    url: `${siteURL}/${slug}/`, // eslint-disable-line
    identifier: id,
    title: title,
  };

  useEffect(() => {
    setTimeout(function () {
      twttr.widgets.load(); // eslint-disable-line
    }, 1000);
  });

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${siteURL}/${slug}/`} />
      </Helmet>
      <Layout>
        <SEO
          title={title}
          description={trimmedString}
          pathname={`${siteURL}/${slug}/`}
          image={featuredImage.node.remoteFile.childImageSharp.fluid.src}
          article
        />
        <div className="Post">
          <span className="Post-date">
            {moment(date).format("dddd, MMMM Do, YYYY")}
          </span>
          <h1 className="Post-title">{title}</h1>

          <span className="Post-author">
            By: {author.node.firstName} {author.node.lastName}
          </span>
          {/* 
        <div className="Post-engagement">
          <CommentCount config={disqusConfig} placeholder={"..."} />
        </div> */}
          {/* 
          {!!featuredImage?.node?.remoteFile?.childImageSharp && (
            <div>
              <Img
                fluid={featuredImage.node.remoteFile.childImageSharp.fluid}
              />
            </div>
          )} */}

          <div
            className="Post-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />

          {/* 
        {!!nextPage && (
          <Link to={normalizePath(nextPage.uri)}>Next: {nextPage.title}</Link>
        )}

        {!!previousPage && (
          <Link to={normalizePath(previousPage.uri)}>
            Previous: {previousPage.title}
          </Link>
        )} */}

          <div className="Post-authorcard">
            <div className="AuthorCard">
              {/* {author.node.avatar.url && (
                <div className="AuthorCard-avatar">
                  <img
                    src={author.node.avatar.url}
                    alt={`${author.node.firstName} ${author.node.lastName}'s Avatar`}
                  />
                </div>
              )} */}
              <div className="AuthorCard-content">
                {author.node.firstName && (
                  <h5 className="AuthorCard-name">
                    {author.node.firstName} {author.node.lastName}
                  </h5>
                )}
                {author.node.description && (
                  <p className="AuthorCard-description">
                    {author.node.description}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="Post-contact">
            <div className="Contact">
              <h4 className="Contact-title">Let's chat!</h4>
              <p className="Contact-intro">
                Have a project coming up or need help executing your own ideas?
                We're available!
              </p>
              <p className="Contact-email">
                Email us at{" "}
                <a href="mailto:contact@madcity.gg" title="Email Mad City">
                  contact@madcity.gg
                </a>
              </p>
            </div>
          </div>

          <div className="Post-comments">
            <Disqus config={disqusConfig} />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default DefaultPost;
