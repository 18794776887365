import React from "react";
import { graphql } from "gatsby";
import DefaultPost from "../../components/template-parts/default-post";

export default ({ data }) => <DefaultPost data={data} />;

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      slug
      title
      excerpt
      content
      date
      author {
        node {
          firstName
          lastName
          description
          avatar {
            default
            url
          }
        }
      }
      featuredImage {
        node {
          remoteFile {
            ...HeroImage
          }
        }
      }
    }

    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }

    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`;
