import React from "react";
import { Link } from "gatsby";
import bolt from "../assets/svg/logo-bolt-2020.svg";

export default () => (
  <>
    <Link to="/">
      <img src={bolt} className="Logo" alt="Mad City Logo" />
    </Link>
  </>
);
