import React from "react";
import { Link } from "gatsby";

export default () => (
  <footer className="South">
    <div className="South-inner">
      <p className="South-copyright">
        &copy; 2015-2020 <Link to="/">Mad City, LLC</Link>
      </p>
      <p className="South-tagline">
        A full service creative agency in the Midwest.
      </p>
    </div>
  </footer>
);
