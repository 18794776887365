import React from "react";
import Header from "./header";
import Footer from "./footer";
// import Menu from "./menu";

import "../scss/app.scss";

const Layout = ({ children }) => (
  <div className="App">
    <div className="App-header">
      <Header />
    </div>
    {/* <Menu /> */}
    <div className="App-content">{children}</div>
    <div className="App-footer">
      <Footer />
    </div>
  </div>
);

export default Layout;
